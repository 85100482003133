<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          
          <vue-json-to-csv :json-data="activityCSV" :csv-title="'activity'">
            <v-btn color="indigo" class="ma-6" outlined style="position: absolute; top: 0px; right: 0px;">
              Download
              <v-icon>mdi-excel</v-icon>
            </v-btn>
          </vue-json-to-csv>
          <v-card-title>Activity</v-card-title>
          <v-card-subtitle>User Actions and Event History</v-card-subtitle>
          
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="activities"
              class="elevation-1"
              :footer-props="{'items-per-page-options':[5, 10, 50, 100, 500, -1]}"
            >
              <template v-slot:item.createdOn="{ item }">
                {{ friendlyDate(item.createdOn) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Profile",
  components: {
    VueJsonToCsv
  },
  data: () => ({
    activities: [],
    headers: [
      { text: "Date", value: "createdOn" },
      {
        text: "User",
        align: "start",
        sortable: false,
        value: "user.username"
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "user.email"
      },
      { text: "Action", value: "action" }
    ],
    activityCSV: []
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    }
  },
  methods: {
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    }
  },
  mounted: function() {
    let vm = this;
    // query api for users
    axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
    axios.get(vm.$root.urls.api + "/activities", {}).then(function(results) {
      vm.activities = results.data;
      // prepare CSV data for download
      let csvData = [];
      results.data.forEach(d => {
        let row = {
          date: d.createdOn,
          user: d.user.username,
          action: d.action
        };
        csvData.push(row);
      });
      vm.activityCSV = csvData;
    });
  }
};
</script>
